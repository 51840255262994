<script setup lang="ts">
import Notification from '@frontend/ui-kit/src/components/Base/Notification.vue';
import Header from '~/components/shared/Common/wide/Header.vue';
import Navbar from '~/components/shared/Common/wide/Navbar.vue';
import { useNotificationStore } from "~/stores/alert/useNotificationStore";

const store = useNotificationStore();

defineProps({
    isError: { type: Boolean, default: false }
});
</script>

<template>
    <v-app class="app">
        <Header />
        <v-layout class="layout">
            <Navbar />
            <div class="main">
                <div class="main-container">        
                    <slot />
                </div>
            </div>
            <Notification :messages="store.messages"/>
        </v-layout>
    </v-app>
</template>

<style scoped lang="scss">
.app {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    background-color: $color-grey-100;

    :deep(.v-application__wrap) {
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
}

.layout {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1 0 auto;
    
    @include responsive('tablet') { 
        flex-direction: column;
    }
}

.main {
    padding: 0 16px 16px 76px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    gap: 32px;
    flex: 1 0 0;

    @include responsive('tablet') {
        align-items: center;
    }
    
    .main-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: 100%;
        width: 100%;
        padding: 24px 20px;

        border-radius: 32px;
        background: $main-card-color;
        box-shadow: $box-shadow-04;
    }
}
</style>